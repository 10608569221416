import React, { FC, useCallback, useEffect } from "react";

import { Box } from "ui/Box";

import { getBreadCrumbsList } from "modules/Header/helpers";
import { BreadCrumbs } from "../BreadCrumbs";
import { Filters } from "../Filters";

import { styles } from "./styles";

import headerApi from "modules/Header/api/headerApi";
import useTooltipsStore from "modules/Header/store/useTooltipsStore";
import { useLocation } from "react-router-dom";

export interface HeaderProps {}

export const Header: FC<HeaderProps> = React.memo(() => {
  const location = useLocation();
  const links = getBreadCrumbsList(location.pathname);
  const { setTooltips } = useTooltipsStore((state) => state);

  // TODO: add here storing into cookies and take at first what is in cookies

  const getTooltips = useCallback(async () => {
    try {
      const res = await headerApi.getTooltips();
      setTooltips(res);
      console.log(res);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTooltips();
  }, [getTooltips]);

  return (
    <Box sx={styles.root}>
      <BreadCrumbs links={links} />
      <Filters />
    </Box>
  );
});
