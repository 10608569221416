import { create } from "zustand";

export interface TooltipsState {
  tooltips: { key: string; content: string }[];
  setTooltips: (tooltips: { key: string; content: string }[]) => void;
}

const useTooltipsStore = create<TooltipsState>()((set) => ({
  tooltips: [],
  setTooltips: (tooltips) => set({ tooltips }),
}));

export const getTooltipByKey = (title: string, key: string) => {
  const { tooltips } = useTooltipsStore.getState();
  const tooltip = tooltips.find((tooltip) => tooltip.key === key);
  return { title: title, subtitle: tooltip?.content || "" };
};

export default useTooltipsStore;
