import api from "api/axios";

const headerApi = {
  getMinimumDate: async () => {
    try {
      const response = await api.get("/time_filter_start_date/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTooltips: async () => {
    try {
      const response = await api.get("/ui_content/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default headerApi;
